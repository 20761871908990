.container {
  display: grid;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 100px;
  padding-right: 100px;
  grid-template-columns: 25% 25% 25% 25%;
}

.containerList {
  display: grid;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 100px;
  padding-right: 100px;
  grid-template-columns: 40%;
}

.elementComponent {
  padding: 10px;
}

.title {
  font-family: 'SF Compact Rounded';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  letter-spacing: 0.3px;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 0;
}
