.myDiv {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  left: 0;
}

.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
}

.elementBar {
  grid-column: 1;
}

.elementLeftHalf {
  background-color: #3eb489;
}

.elementRightHalf {
  background-color: #e0e0e0;
}
